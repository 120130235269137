<template>
  <div class="person-card">
    <div class="person-card__aside">
      <v-img :src="image" class="person-card__image" />
    </div>
    <div class="person-card__main">
      <div class="person-card__post" v-if="post">
        {{ post }}
      </div>
      <div class="person-card__name">
        <div class="person-card__name-text" v-if="name">
          {{ name }}
        </div>
        <div class="person-card__name-actions" v-if="$scopedSlots.actions">
          <slot name="actions"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String
    },
    name: {
      type: String
    },
    post: {
      type: String
    },
    image: {
      type: String
    },
  }
}
</script>

<style lang="scss" scoped>
$image-size: 40px;
.person-card {
  display: flex;
  grid-gap: 8px;
  align-items: center;
}

.person-card__aside {
  flex: 0 0 auto;
}

.person-card__main {
  flex: 1 1 auto;
}

.person-card__post {
  margin-bottom: 4px;
}

.person-card__contacts {
  margin-top: 8px;
}

.person-card__image {
  background: #eee;
  display: block;
  width: $image-size;
  height: $image-size;
  border-radius: 50%;
  object-fit: cover;
}

.person-card__name {
  display: flex;
  grid-gap: 8px;
  align-items: center;
}
</style>
