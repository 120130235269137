var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mb-8"},[_c('v-col',{attrs:{"cols":"5"}},[_c('div',{staticClass:"text-h5 mb-4"},[_vm._v(_vm._s(_vm.clinic.name))]),_c('v-row',{staticClass:"mb-4 mt-4"},[_c('v-col',[_c('item-meta',{model:{value:(_vm.clinic.meta),callback:function ($$v) {_vm.$set(_vm.clinic, "meta", $$v)},expression:"clinic.meta"}})],1)],1),_c('locale-field',{attrs:{"name":"metaDescription","component":"v-textarea","auto-grow":"","dense":"","outlined":"","hide-details":"","rows":2,"label":"Описание"},model:{value:(_vm.clinic.metaDescription),callback:function ($$v) {_vm.$set(_vm.clinic, "metaDescription", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"clinic.metaDescription"}})],1),_c('v-col',[_c('clinic-sharing-preview',{staticClass:"cv__share",attrs:{"title":_vm.clinic.meta.title && _vm.clinic.meta.title.ru ? _vm.clinic.meta.title.ru : _vm.clinic.name,"description":_vm.clinic.metaDescription.ru,"image":_vm.clinic.socialImage},on:{"input:image":function($event){_vm.clinic.socialImage = $event}}})],1)],1),_c('div',{staticClass:"mb-8"},[_c('div',{staticClass:"text-h4"},[_vm._v(" Заглавное изображение ")]),_c('clinic-hero',{attrs:{"value":{
        desktop: _vm.clinic.mainWidePhoto,
        mobile: _vm.clinic.mainSquarePhoto,
      }},on:{"input":function (ref) {
        var desktop = ref.desktop;
        var mobile = ref.mobile;

        _vm.clinic.mainSquarePhoto = mobile
        _vm.clinic.mainWidePhoto = desktop
      }}})],1),_c('div',[_c('div',{staticClass:"text-h4 mb-4"},[_vm._v(" Элементы страницы ")]),_c('clinic-blocks',{model:{value:(_vm.clinic),callback:function ($$v) {_vm.clinic=$$v},expression:"clinic"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }