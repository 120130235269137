<script>
import employeeService from '@/services/employee'
import { createRequest } from '@/utils/requestsFactory'
import GeneralManager from '@/models/GeneralManager'

export default {
  props: {
    value: {
      type: Array,
    },
  },
  data () {
    return {
      req: createRequest(async () => {
        const requests = this.value.map(async (item) => {
          const employee = await employeeService.get(item.employeeId)
          return new GeneralManager({ ...item, employee })
        })
        const data = await Promise.all(requests)
        this.$nextTick(() => this.$emit('input', data))
        return data
      })
    }
  },
  created () {
    return this.req.run()
  },
  render () {
    const { default: defaultSlot } = this.$scopedSlots
    return defaultSlot ? defaultSlot(this.req) : null
  }
}
</script>
