<template>
  <div class="clinic-contact">
    <v-row>
      <v-col>
        <div class="title mb-4">Название контакта</div>
        <div class="d-flex justify-space-between">
          <div style="width: 100%">
            <locale-field
              component="v-text-field"
              block
              rules="required"
              dense
              v-model="innerValue.name"
              name="name"
            />
          </div>
          <v-btn
            class="ml-4"
            color="error"
            title="Удалить"
            icon
            depressed
            @click="$emit('remove')"
            small
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>

        <v-row>
          <v-col lg="6">
            <validation-provider
              name="Code"
              v-slot="{ errors }"
            >
              <v-text-field
                dense
                v-model="innerValue.code"
                name="code"
                label="Код"
                :error-messages="errors"
                hint="Например, duty-doctor или stomatology"
              />
            </validation-provider>
          </v-col>
        </v-row>


        <div class="title mt-2">Контакты</div>
        <v-row>
          <v-col
            cols="4"
            v-for="contact in contacts"
            :key="contact.$key"
          >
            <v-text-field
              v-bind="contact.props"
              dense
              hide-details
              :value="innerValue[contact.modelName]"
              @input="($event) => innerValue[contact.modelName] = $event"
            />
          </v-col>
        </v-row>


        <div class="title mt-6 mb-4">Комментарий</div>
        <locale-field
          component="v-textarea"
          v-model="innerValue.comment"
          dense
          hide-details
          :rows="1"
          name="comment"
        />

      </v-col>
    </v-row>
  </div>
</template>

<script>
import LocaleField from '@/components/Locale/LocaleField.vue'
import isEqual from 'lodash/isEqual'
const MODEL_PROP = 'contact'
const MODEL_EVT = 'input'

class Model {
  constructor (opts) {
    const options = opts || {}
    this.code = options.code || null,
    this.name = options.name || {},
    this.phone = options.phone || null,
    this.telegram = options.telegram || null
    this.whatsapp = options.whatsapp || null
    this.instagram = options.instagram || null
    this.facebook = options.facebook || null
    this.comment = options.comment || null
  }
}

export default {
  components: {
    LocaleField
  },
  model: {
    prop: MODEL_PROP,
    event: MODEL_EVT
  },
  props: {
    [MODEL_PROP]: {}
  },
  data () {
    return {
      innerValue: new Model(this[MODEL_PROP] || {})
    }
  },
  watch: {
    [MODEL_PROP]: {
      deep: true,
      handler (contact, oldValue) {
        if (isEqual(contact, oldValue)) return
        this.innerValue = contact
      }
    },
    innerValue: {
      deep: true,
      immediate: true,
      handler (innerValue) {
        this.$emit(MODEL_EVT, innerValue)
      }
    }
  },
  computed: {
    contacts () {
      return [
        {
          $key: 'phone',
          modelName: 'phone',
          props: {
            name: 'phone',
            label: 'Телефон'
          }
        },
        {
          $key: 'telegram',
          modelName: 'telegram',
          props: {
            name: 'telegram',
            label: 'Telegram'
          }
        },
        {
          $key: 'whatsapp',
          modelName: 'whatsapp',
          props: {
            name: 'whatsapp',
            label: 'Whatsapp'
          }
        },
        {
          $key: 'instagram',
          modelName: 'instagram',
          props: {
            name: 'instagram',
            label: 'Instagram'
          }
        },
        {
          $key: 'facebook',
          modelName: 'facebook',
          props: {
            name: 'facebook',
            label: 'Facebook'
          }
        },
      ]
    }
  }
}
</script>
