<template>
  <blocks-editor
    v-on="$listeners"
    v-bind="{
      ...$attrs,
      fields
    }"
  />
</template>

<script>
import {
  BLOCK_TYPE_ABOUT,
  BLOCK_TYPE_DOCTORS,
  BLOCK_TYPE_SERVICES,
  BLOCK_TYPE_ARTICLES,
  BLOCK_TYPE_LOCATION,
  BLOCK_TYPE_BANNER,
  BLOCK_TYPE_GALLERY,
  BLOCK_TYPE_CONTENT,
} from '@/services/clinics'
import BlocksEditor from '@/components/BlocksEditor/BlocksEditor.vue'
import BlocksEditorFieldAbout from '@/components/BlocksEditor/BlocksEditorField/BlocksEditorFieldAbout.vue'
import BlocksEditorFieldDoctors from '@/components/BlocksEditor/BlocksEditorField/BlocksEditorFieldDoctors.vue'
import BlocksEditorFieldServices from '@/components/BlocksEditor/BlocksEditorField/BlocksEditorFieldServices.vue'
import BlocksEditorFieldArticles from '@/components/BlocksEditor/BlocksEditorField/BlocksEditorFieldArticles.vue'
import BlocksEditorFieldLocation from '@/components/BlocksEditor/BlocksEditorField/BlocksEditorFieldLocation.vue'
import BlocksEditorFieldBanner from '@/components/BlocksEditor/BlocksEditorField/BlocksEditorFieldBanner.vue'
// import BlocksEditorFieldGallery from '@/components/BlocksEditor/BlocksEditorField/BlocksEditorFieldGallery.vue'
import BlocksEditorFieldGalleryExtended from '../BlocksEditor/BlocksEditorField/BlocksEditorFieldGalleryExtended.vue'
import BlocksEditorFieldContent from '../BlocksEditor/BlocksEditorField/BlocksEditorFieldContent.vue'

export default {
  components: {
    BlocksEditor,
  },
  data () {
    return {
      fields: [
        {
          name: BLOCK_TYPE_ARTICLES,
          label: 'Статьи',
          view: BlocksEditorFieldArticles,
          value: 'journalPosts'
        },
        {
          name: BLOCK_TYPE_ABOUT,
          label: 'Описание',
          view: BlocksEditorFieldAbout,
          value: 'description',
        },
        {
          name: BLOCK_TYPE_DOCTORS,
          label: 'Список докторов',
          view: BlocksEditorFieldDoctors,
          value: 'attachedDoctors',
        },
        {
          name: BLOCK_TYPE_SERVICES,
          label: 'Услуги',
          view : BlocksEditorFieldServices,
          value: 'attachedServices'
        },
        {
          name: BLOCK_TYPE_LOCATION,
          label: 'Расположение',
          view: BlocksEditorFieldLocation,
          value: 'address'
        },
        {
          name: BLOCK_TYPE_BANNER,
          label: 'Баннер',
          view: BlocksEditorFieldBanner,
          value: null
        },
        {
          name: BLOCK_TYPE_GALLERY,
          label: 'Галерея',
          view: BlocksEditorFieldGalleryExtended,
          value: 'gallery'
        },
        {
          name: BLOCK_TYPE_CONTENT,
          label: 'Content',
          view: BlocksEditorFieldContent,
          value: null,
        }
      ]
    }
  }
}
</script>
