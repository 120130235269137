<template>
  <figure class="share-preview">
    <div class="share-preview__image">
      <slot name="image" v-bind="{ classList: 'share-preview__image-img' }">
        <img :src="image" class="share-preview__image-img">
      </slot>
    </div>
    <div class="share-preview__text">
      <div class="share-preview__title font-weight-bold">{{ title }}</div>
      <div class="share-preview__description">{{ description }}</div>
    </div>
  </figure>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
    },
    title: {
      type: String,
    },
    description: {
      type: String
    },
  },
}
</script>


<style lang="scss" scoped>
.share-preview {
  background: #fff;
  overflow: hidden;
  box-shadow: 4px 4px 20px rgba(#000, 0.1),
              4px 4px 5px rgba(#000, 0.05);
}

.share-preview__image {
  position: relative;
  overflow: hidden;
  &:before {
    content: '';
    display: block;
    padding-bottom: 55%;
  }
}

.share-preview__image-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.share-preview__text {
  padding: 8px 12px 16px;
}
</style>
