<template>
  <div>
    <v-row :key="item.employee.id" v-for="item, idx in items">
      <v-col>
        <clinic-manager
          :value="item"
          @input="(payload) => updateItem(idx, payload)"
          class="mb-8"
        />
      </v-col>
      <v-col class="col-auto">
        <v-btn icon style="margin-top: 16px" @click="() => removeItem(idx)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6" offset="3">
        <v-btn block color="primary" @click="() => adding = true">
          <v-icon left>mdi-plus</v-icon>
          {{ $t('buttons.add') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="adding" max-width="900">
      <clinic-manager-add v-if="adding" @cancel="adding = false" @input="addItem" />
    </v-dialog>

    <confirm v-model="confirm" v-bind="confirm" />
  </div>
</template>

<script>
import isEqual from 'lodash/isEqual'
import ClinicManager from './ClinicManager.vue'
import ClinicManagerAdd from './ClinicManagerAdd.vue'
import Confirm from '@/components/Confirm/Confirm.vue'

export default {
  components: {
    Confirm,
    ClinicManagerAdd,
    ClinicManager,
  },
  props: {
    value: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      confirm: false,
      adding: false,
      items: [ ...this.value ]
    }
  },
  watch: {
    value: {
      deep: true,
      handler (value) {
        if (isEqual(value, this.items)) return
        this.items = value
      }
    }
  },
  methods: {
    input () {
      this.$emit('input', this.items)
    },
    addItem (manager) {
      this.items.unshift(manager)
      this.adding = false
      this.input()
    },
    updateItem (idx, payload) {
      const item = this.items[idx]
      const data = { ...item.details, ...payload }
      this.$set(this.items, idx, data)
      this.input()
    },
    removeItem (idx) {
      const item = this.items[idx]
      this.confirm = {
        title: this.$t('clinic.generalManagerRemoveConfirmText'),
        onConfirm: () => {
          this.items.splice(idx, 1)
          this.input()
        },
      }
    }
  }
}
</script>
