<template>
  <div>
    <v-row>
      <v-col>
        <validation-provider
          rules="required"
          name="station"
          v-slot="{ errors }"
        >
          <v-text-field
            outlined
            dense
            hide-details
            v-model="innerValue.station"
            name="station"
            label="Станция"
            :error-messages="errors[0]"
          />
        </validation-provider>
      </v-col>
      <v-col>
        <v-text-field
          outlined
          dense
          hide-details
          v-model="innerValue.lineName"
          name="lineName"
          label="Линия"
        />
      </v-col>
      <v-col>
        <v-text-field
          outlined
          dense
          hide-details
          v-model="innerValue.line"
          name="lineNumber"
          label="Номер линии"
        />
      </v-col>
      <v-col>
        <v-menu :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <div class="d-flex" style="align-items: center; grid-gap: 12px">
              <v-text-field
                outlined
                dense
                hide-details
                v-model="innerValue.color"
                disabled
                label="Цвет"
              />
              <v-icon
                v-on="on"
                v-bind="attrs"
                title="Изменить цвет"
                :style="{ color: innerValue.color }"
              >fa-circle</v-icon>
            </div>
          </template>
          <v-color-picker
            :value="innerValue.color"
            @update:color="innerValue.color = $event.hex"
          />
        </v-menu>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import isEqual from 'lodash/isEqual'

export class Metro {
  constructor (options) {
    const { line, lineName, station, color } = options || {}
    Object.assign(this, {
      line,
      lineName,
      station,
      color
    })
  }
}

export default {
  props: {
    value: {
      type: Object,
    }
  },
  data () {
    return {
      innerValue: new Metro(this.value),
    }
  },
  watch: {
    innerValue: {
      deep: true,
      handler (innerValue) {
        this.$emit('input', innerValue)
      }
    },
    value: {
      deep: true,
      handler (value) {
        if (isEqual(value, this.innerValue)) {
          return
        } else {
          this.innerValue = value
        }
      }
     }
  }
}
</script>
