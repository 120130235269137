<template>
  <div class="share-image-editor">
    <share-preview v-bind="$attrs" v-on="$listeners">
      <template #image>
        <image-select
          :value="image"
          class="share-image-editor__image"
          @input="$emit('input:image', $event)"
        />
      </template>
    </share-preview>
    <image-requirements
      class="mt-2 caption"
      ratio="1.91/1"
      size="не менее 1200x630"
    />
  </div>
</template>

<script>
import SharePreview from './SharePreview.vue'
import ImageSelect from '@/components/elements/image-select.vue'

export default {
  components: {
    SharePreview,
    ImageSelect
  },
  props: {
    image: {
      type: Object
    },
  }
}
</script>

<style lang="scss" scoped>
.share-image-editor__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
