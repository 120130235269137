<template>
  <div class="clinic-metro">
    <v-btn
      v-if="!enabled"
      @click="enabled = true"
      color="success"
    >
      Добавить
    </v-btn>
    <template v-else>
      <metro
        :value="value"
        @input="$emit('input', $event)"
      />
      <v-btn @click="remove" color="error">
        Удалить
      </v-btn>
    </template>
  </div>
</template>

<script>
import Metro from '../../components/Metro/Metro.vue'

export default {
  components: {
    Metro
  },
  props: {
    value: {
      validator: (prop) => {
        return typeof prop === 'object' || prop === null
      }
    }
  },
  data () {
    return {
      enabled: Boolean(this.value)
    }
  },
  methods: {
    remove () {
      if (confirm('Действительно хотите удалить метро?')) {
        this.enabled = false
        this.$emit('input', null)
      }
    }
  }
}
</script>
