<template>
  <v-select
    v-bind="{
      items: types,
      itemValue: 'value',
      itemText: 'text',
      label: $t('form.labels.type'),
      ...$attrs
    }"
    v-on="$listeners"
  />
</template>

<script>
import { TYPE_CHIEF_OF_MEDICINE, TYPE_CLINIC_OPERATION_MANAGER } from '@/models/GeneralManager'

export default {
  computed: {
    types () {
      return [
        {
          value: TYPE_CHIEF_OF_MEDICINE,
          text: this.$t(`clinic.generalManagerTypes.${TYPE_CHIEF_OF_MEDICINE}`)
        },
        {
          value: TYPE_CLINIC_OPERATION_MANAGER,
          text: this.$t(`clinic.generalManagerTypes.${TYPE_CLINIC_OPERATION_MANAGER}`)
        }
      ]
    }
  }
}
</script>
