<template>
  <v-dialog
    v-bind="props"
    @keydown.esc="!props.persistent ? cancel : null"
    @click:outside="!props.persistent ? cancel : null"
  >
    <div class="text-h5 mb-4" v-if="title">
      {{ title }}
    </div>
    <div v-html="text" class="mb-4" v-if="text" />
    <v-row>
      <v-col :key="item.$key" v-for="item in items">
        <v-btn
          @click="item.click"
          v-bind="item.bind"
        >
          {{ item.text }}
        </v-btn>
      </v-col>
    </v-row>
  </v-dialog>
</template>

<script>
export const EVT_RESOLVED = 'resolved'
export const EVT_CONFIRMED = 'confirmed'
export const EVT_CANCELLED = 'cancelled'
export const EVT_INPUT = 'input'

export default {
  props: {
    title: {
      type: String
    },
    text: {
      type: String
    },
    onConfirm: {
      type: Function,
    },
    onCancel: {
      type: Function
    }
  },
  computed: {
    props () {
      return {
        persistent: true,
        maxWidth: 640,
        ...this.$attrs
      }
    },
    listeners () {
      const { input, ...listeners } = this.$listeners
      return listeners
    },
    items () {
      return [
        {
          $key: 'confirm',
          text: this.$t('buttons.confirm'),
          click: () => this.confirm(),
          bind: {
            block: true,
          }
        },
        {
          $key: 'cancel',
          text: this.$t('buttons.cancel'),
          click: () => this.cancel(),
          bind: {
            block: true,
            color: 'primary',
          }
        }
      ]
    }
  },
  methods: {
    async confirm () {
      if (typeof this.onConfirm === 'function') {
        await this.onConfirm()
      }
      this.$emit(EVT_INPUT, false)
      this.$emit(EVT_CONFIRMED)
      this.$emit(EVT_RESOLVED, true)
    },
    async cancel () {
      if (typeof this.onCancel === 'function') {
        await this.onCancel()
      }
      this.$emit(EVT_INPUT, false)
      this.$emit(EVT_CANCELLED)
      this.$emit(EVT_RESOLVED, false)
    }
  }
}
</script>
