<template>
  <div class="content has-text-centered daysform">
    <div>
      <v-row>
        <v-col cols="3">
          <div class="text-h5">График работы</div>
        </v-col>
        <v-col cols="6">
          <v-switch
            name="soon"
            v-model="form.soon"
            :value="false"
            label="Показать надпись «Скоро открытие»"
          />
        </v-col>
        <v-col cols="3">
          <v-checkbox
            v-model="form.aroundTheClock"
            label="Круглосуточно"
          />
        </v-col>
      </v-row>

      <v-row v-for="day in form.daysOfWeek" :key="day.name">
        <v-col cols="12" sm="2">
          <v-text-field
            disabled
            autocomplete="off"
            :value="day.name"
            label="День недели"
            hide-details
            dense
            outlined
            single-line
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="2">
          <v-text-field
            autocomplete="off"
            v-model="day.from"
            label="Время открытия"
            hide-details
            outlined
            light
            dense
            v-mask="'##:##'"
            :disabled="day.closed"
            :style="{ opacity: day.closed ? '0.5' : '1' }"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="2">
          <v-text-field
            v-model="day.to"
            autocomplete="off"
            label="Время закрытия"
            hide-details
            outlined
            light
            dense
            v-mask="'##:##'"
            :disabled="day.closed"
            :style="{ opacity: day.closed ? '0.5' : '1' }"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="1">
          <v-checkbox
            v-model="day.closed"
            label="Выходной"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-divider style="margin: 20px 0"></v-divider>

      <div style="margin: 40px 0; max-width: 860px">
        <v-textarea
          outlined
          dense
          light
          hide-details
          rows="1"
          auto-grow
          v-model="form.comment"
          label="Комментарий"
        ></v-textarea>
      </div>
    </div>
  </div>
</template>

<script>
import ClinicSchedule from "../../../../../ui/src/models/ClinicSchedule"
import { DAYS_OF_WEEK } from "../../../utils/vuetifiy"

const dayNames = DAYS_OF_WEEK.map((v) => v.text)

export default {
  model: {
    prop: "schedule",
    event: "input"
  },
  props: {
    schedule: {
      type: [Object, null]
    },
  },
  data () {
    const { daysOfWeek, comment, soon, aroundTheClock } = new ClinicSchedule(this.schedule)

    return {
      form: {
        comment,
        soon,
        aroundTheClock,
        daysOfWeek: daysOfWeek.map((day, idx) => ({
          ...day,
          name: dayNames[idx],
          from: day.from,
          to: day.to
        }))
      }
    }
  },
  watch: {
    form: {
      handler ({ comment, soon, daysOfWeek, aroundTheClock }) {
        const schedule = new ClinicSchedule({ comment, soon, daysOfWeek, aroundTheClock })
        this.$emit('input', schedule)
      },
      deep: true
    }
  }
}
</script>

<style>
.daysform .theme--light.v-input--is-disabled input, .theme--light.v-input--is-disabled textarea{
  color: rgba(0, 0, 0, 0.87)
}
</style>
