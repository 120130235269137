import BaseService from './baseService'

class EmployeeService extends BaseService {
  get url () {
    return 'employees'
  }
}

export default new EmployeeService()

