<template>
  <validation-observer
    ref="observer"
    tag="form"
    @submit.prevent="save"
  >

    <div class="text-h5">
      {{ $t('clinic.generalManagerAddingTitle') }}
    </div>

    <clinic-manager v-model="manager" class="mb-4"/>

    <v-row>
      <v-col cols="6">
        <v-btn block color="primary" type="submit">
          {{ $t('buttons.done') }}
        </v-btn>
      </v-col>
      <v-col offset="4">
        <v-btn block @click="$emit('cancel')">{{ $t('buttons.cancel') }}</v-btn>
      </v-col>
    </v-row>
  </validation-observer>
</template>

<script>
import ClinicManager from './ClinicManager.vue'
import GeneralManager from '@/models/GeneralManager'

export default {
  components: {
    ClinicManager
  },
  data () {
    return {
      manager: new GeneralManager()
    }
  },
  methods: {
    async save () {
      const valid = await this.$refs.observer.validate()
      if (!valid) return
      this.$emit('input', this.manager)
      this.$nextTick(() => {
        this.manager = new GeneralManager()
      })
    }
  }
}
</script>
