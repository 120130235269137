<template>
  <AutocompleteAsync
    v-bind="attrs"
    v-on="$listeners"
  />
</template>

<script>
import AutocompleteAsync from '@/components/Cart/AutocompleteAsync.vue'
import service from '@/services/employee'
import { getPatientFullName } from '@/utils/format'

export default {
  components: {
    AutocompleteAsync
  },
  computed: {
    attrs () {
      return {
        name: "employee",
        placeholder: this.$t('form.labels.employee'),
        itemText: 'fullName',
        itemValue: 'id',
        load: (...args) => this.load(...args),
        ...this.$attrs,
      }
    }
  },
  methods: {
    async load (params) {
      const { items } = await service.getAll(params)
      return items.map((item) => ({
        ...item,
        fullName: getPatientFullName(item)
      }))
    },
  }
}
</script>
