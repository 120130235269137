<template>
  <div class="clinic-contacts">
    <items-constructor-provider
      v-model="items"
      v-slot="{ add, remove }"
    >
      <div class="clinic-contacts__list">
        <div
          v-for="(item, idx) in items"
          :key="item.key"
        >
          <clinic-contact
            v-model="item.contact"
            @remove="deleteItem(remove, idx)"
          />
        </div>
      </div>

      <v-btn color="success" large @click="create(add)">
        <v-icon small left>mdi-plus</v-icon>
        Добавить контакт
      </v-btn>
    </items-constructor-provider>
  </div>
</template>

<script>
import ItemsConstructorProvider from '../ItemsConstructorProvider.vue'
import ClinicContact from './ClinicContact.vue'

class Contact {
  constructor (contact) {
    this.key = Math.random()
    this.contact = contact || null
  }
}

export default {
  components: {
    ClinicContact,
    ItemsConstructorProvider
  },
  model: {
    prop: 'contacts',
    event: 'input'
  },
  props: {
    contacts: {
      type: Array,
    },
  },
  data () {
    return {
      items: (this.contacts || []).map((contact) => new Contact(contact))
    }
  },
  watch: {
    items: {
      deep: true,
      handler (items) {
        this.$emit('input', items.map(({ contact }) => contact))
      }
    }
  },
  methods: {
    create (add) {
      return add(new Contact())
    },
    deleteItem (remove, idx) {
      if (!confirm('Удалить?')) return
      remove(idx)
    }
  }
}
</script>

<style lang="scss" scoped>
.clinic-contacts__list {
  & > * {
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
    &:last-child {
      margin-bottom: 0;
      border-bottom: 0;
    }
  }
}

.clinic-contacts__errors {
  & > * {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
