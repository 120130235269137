export const TYPE_CHIEF_OF_MEDICINE = 'chiefOfMedicine'
export const TYPE_CLINIC_OPERATION_MANAGER = 'clinicOperationManager'

export default class GeneralManager {
  constructor (options) {
    const {
      employeeId,
      type,
      employee,
      contacts = []
    } = options || {}
    return {
      employeeId: employeeId || employee?.id,
      type,
      employee,
      contacts,
    }
  }
}
