<template>
<validation-observer
  ref="validator"
  v-slot="{ handleSubmit, errors, invalid }"
>
  <item-edit-view
    title="Клиника"
    :subtitle="item ? item.name : null"
    :loading="loading"
  >
    <template v-slot:right>
      <v-btn
        right
        color="success"
        :loading="saving"
        @click.prevent="submit"
      >
        <v-icon small>mdi-content-save</v-icon>
        Сохранить
      </v-btn>
    </template>

    <v-card>
      <v-form v-if="!loading && item">
        <v-tabs v-model="tab" background-color="primary" dark>
          <v-tab key="info">Главное</v-tab>
          <v-tab key="page">Страница</v-tab>
          <v-tab key="workTime">Расписание</v-tab>
          <v-tab key="location">Расположение</v-tab>
          <v-tab key="workplace">Workplace</v-tab>
          <v-tab key="contacts">Контакты</v-tab>
          <v-tab key="management">Руководители</v-tab>
        </v-tabs>

        <v-card-text>

          <!-- TODO: убрать эту простыню -->
          <v-tabs-items v-model="tab">

            <v-tab-item key="info" eager>

              <v-text-field
                outlined
                dense
                class="mb-4"
                hide-details
                v-model="item.name"
                label="Полное название"
                :disabled="true"
              />

              <locale-field
                outlined
                dense
                hide-details
                component="v-text-field"
                v-model="item.shortName"
                label="Короткое название"
                rules="required"
                name="shortName"
              />

              <br>

              <locale-field
                dense
                hide-details
                outlined
                component="v-text-field"
                v-model="item.shortNameLocativeCase"
                name="shortNameLocativeCase"
                label="Короткое название (в местном падеже)"
                rules="required"
              />

              <v-text-field
                v-model="item.shortNameEng"
                dense
                hide-details
                outlined
                class="mt-8"
                disabled
                label="Короткое название (eng)"
                placeholder="Короткое название (eng)"
                required
              ></v-text-field>

              <div class="mb-2 mt-8">
                Фото для карточки
              </div>

              <image-select style="width: 400px; height: 220px" v-model="item.cardImage" />
              <image-requirements class="caption" size="1200px" ratio="2:1" />

              <h2 class="text-h4 mt-12 mb-2">
                Служебные
              </h2>

              <label class="checkbox">
                <input type="checkbox" v-model="item.private">
                Скрыто
              </label>

              <v-text-field
                dense
                outlined
                class="mt-4"
                v-model.trim="item.uri"
                label="Uri"
              ></v-text-field>

              <v-text-field
                dense
                outlined
                class="mt-4"
                v-model.trim="item.externalUrl"
                label="Внешняя ссылка"
              ></v-text-field>

              <v-text-field
                outlined
                dense
                v-model.trim="item.stampFilename"
                label="Название файла с изображением печати"
              ></v-text-field>

              <v-select
                outlined
                dense
                v-model="item.workDaysOfWeekOfDutyDoctor"
                :items="daysOfWeek"
                label="Дни недели работы дежурного доктора"
                multiple
                chips
                persistent-hint
              ></v-select>

            </v-tab-item>

            <v-tab-item key="page">
              <clinic-page-editor v-model="item" />
            </v-tab-item>

            <v-tab-item key="workTime">
              <!-- <WorkTime :workTimeList="item.workTimes" ref="childComponentRef"/> -->
              <WorkTime v-model="item.workTimes" />
            </v-tab-item>

            <v-tab-item key="location">
              <locale-field
                name="address"
                outlined
                auto-grow
                dense
                :rows="2"
                hide-details
                component="v-textarea"
                v-model.trim="item.address"
                label="Адрес"
              />

              <locale-field
                rows="1"
                auto-grow
                component="v-textarea"
                v-model="item.parkingInfo"
                name="parkingInfo"
                label="Инфа о парковке"
                dense
                outlined
                hide-details
                class="mb-12 mt-8"
              ></locale-field>

              <v-text-field
                outlined
                dense
                hide-details
                v-model.trim="item.coordinates"
                label="Координаты"
                class="mb-4 mt-8"
              ></v-text-field>

              <v-text-field
                outlined
                dense
                hide-details
                class="mb-8"
                v-model.trim="item.googlePlaceId"
                label="Google place id"
                hint="https://developers.google.com/maps/documentation/javascript/examples/places-placeid-finder"
              ></v-text-field>

              <div class="text-body">
                Метро
              </div>

              <ClinicMetro v-model="item.metro" class="mb-8" />

              <v-text-field
                v-model="item.mapUrl"
                name="mapUrl"
                label="Embed-карта"
                placeholder="https://..."
                dense
                outlined
                hide-details
              ></v-text-field>

              <div class="text-h5 mb-4 mt-10">
                Как добраться
              </div>
              <WayToClinic
                :items="item.howToGetHere"
                @remove-way="removeWay"
              />
            </v-tab-item>
            <v-tab-item key="workplace">
              <v-text-field
                v-model.trim="item.receptionGroupWorkplaceId"
                label="Id группы ресепшен/walk-in"
              ></v-text-field>

              <v-text-field
                v-model.trim="item.carPassGroupWorkplaceId"
                label="Id группы заказа пропуска на автомобиль"
              ></v-text-field>

              <v-text-field
                v-model.trim="item.mainAdministratorWorkplaceId"
                label="Id главного администратора"
              ></v-text-field>

            </v-tab-item>

            <v-tab-item key="contacts" eager>
              <v-row>
                <v-col cols="8">
                  <clinic-contacts v-model="item.contacts" />
                </v-col>
                <v-col>
                  <clinic-main-contact
                    v-model="item.mainContact"
                    name="mainContact"
                    label="Основной контакт"
                  />
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item key="management" eager>
              <clinic-managers-provider
                v-model="item.generalManagers"
                v-slot="{ pending }"
              >
                <div v-if="pending">{{ $t('notes.loading') }}</div>
                <clinic-managers-editor v-else v-model="item.generalManagers" class="mb-12" />
              </clinic-managers-provider>
            </v-tab-item>

          </v-tabs-items>
        </v-card-text>
      </v-form>
    </v-card>
  </item-edit-view>
</validation-observer>
</template>

<script>
import LocaleField from '@/components/Locale/LocaleField.vue'
import clinicService from '../../services/clinics'
import createOrUpdateViewMixin from '../../mixins/createOrUpdateView'
import { QUILL_BASIC } from '../../components/elements/quilljs/configs'
import WorkTime from '../../components/elements/form/WorkTime'
import WayToClinic from '../../components/WayToClinic.vue'
import { DAYS_OF_WEEK } from "../../utils/vuetifiy"
import ClinicContacts from '../../components/Clinic/ClinicContacts.vue'
import { Notification } from 'element-ui'
import ClinicMetro from '../../components/Clinic/ClinicMetro.vue'
import ClinicPageEditor from '../../components/Clinic/ClinicPageEditor'
import ImageSelect from '../../components/elements/image-select.vue'
import ClinicManagersEditor from '@/components/Clinic/ClinicManagersEditor.vue'
import ClinicManagersProvider from '@/components/Clinic/ClinicManagersProvider.vue'
import ClinicMainContact from './ClinicMainContact.vue'

const QUILL_LIMIT_MAX_LENGTH = {
  ...QUILL_BASIC,
  modules: {
    ...QUILL_BASIC.modules,
    limit: {
      numberSymbols: 5000
    }
  }
}

export default {
  mixins: [createOrUpdateViewMixin(clinicService)],
  components:{
    LocaleField,
    WorkTime,
    WayToClinic,
    ClinicContacts,
    ClinicMetro,
    ClinicPageEditor,
    ImageSelect,
    ClinicManagersEditor,
    ClinicManagersProvider,
    ClinicMainContact,
  },
  data () {
    return {
      contactsList: null,
      quillToolbar: QUILL_LIMIT_MAX_LENGTH,
      tab: null,
      daysOfWeek: DAYS_OF_WEEK,
    }
  },
  methods: {
    async submit () {
      const valid = await this.$refs.validator.validate()
      if (!valid) {
        const errors = Object.values(this.$refs.validator.errors)
        Notification({
          title: 'Проверьте правильность введенных данных',
          type: 'error',
          dangerouslyUseHTMLString: true,
          message: errors
            .map((items) => items.join(', '))
            .filter((item) => item)
            .join(', <br>')
        })
      } else {
        this.save()
      }
    },
    removeWay(value) {
      this.item.howToGetHere = value
    },
  }
}
</script>
