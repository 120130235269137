class Day {
  constructor ({ from= null, to = null, closed = false } = {}) {
    this.from = from
    this.to = to
    this.closed = closed
  }
}

module.exports = class ClinicSchedule {
  constructor (options) {
    const { aroundTheClock = false, comment = '', soon = false, daysOfWeek = [] } = options || {}
    this.comment = comment
    this.soon = soon
    this.daysOfWeek = []
    this.aroundTheClock = aroundTheClock
    for (let i = 0; i < 7; i++) {
      this.daysOfWeek.push(new Day(daysOfWeek[i]))
    }
  }
}
