<template>
  <v-select
    v-bind="$attrs"
    v-on="$listeners"
    item-text="text"
    item-value="value"
    :items="items"
  />
</template>

<script>
import { clinicContacts, CONTACT_PHONE, CONTACT_TG } from '@/models/ClinicContacts'

const names = {
  [CONTACT_PHONE]: 'Телефон',
  [CONTACT_TG]: 'Telegram',
}

export default {
  data () {
    return {
      items: Object.values(clinicContacts).map((value) => ({
        text: names[value] || value,
        value
      }))
    }
  }
}
</script>
