<template>
  <div>
    <v-row class="mb-8">
      <v-col cols="5">
        <div class="text-h5 mb-4">{{ clinic.name }}</div>

        <v-row class="mb-4 mt-4">
          <v-col>
            <item-meta v-model="clinic.meta" />
          </v-col>
        </v-row>

        <locale-field
          name="metaDescription"
          component="v-textarea"
          auto-grow
          dense
          outlined
          hide-details
          :rows="2"
          v-model.trim="clinic.metaDescription"
          label="Описание"
        />
      </v-col>
      <v-col>
        <clinic-sharing-preview
          class="cv__share"
          :title="clinic.meta.title && clinic.meta.title.ru ? clinic.meta.title.ru : clinic.name"
          :description="clinic.metaDescription.ru"
          :image="clinic.socialImage"
          @input:image="clinic.socialImage = $event"
        />
      </v-col>
    </v-row>

    <div class="mb-8">
      <div class="text-h4">
        Заглавное изображение
      </div>
      <clinic-hero
        @input="({ desktop, mobile }) => {
          clinic.mainSquarePhoto = mobile
          clinic.mainWidePhoto = desktop
        }"
        :value="{
          desktop: clinic.mainWidePhoto,
          mobile: clinic.mainSquarePhoto,
        }"
      />
    </div>

    <div>
      <div class="text-h4 mb-4">
        Элементы страницы
      </div>
      <clinic-blocks v-model="clinic" />
    </div>
  </div>
</template>

<script>
import LocaleField from '@/components/Locale/LocaleField.vue'
import ClinicSharingPreview from './ClinicSharingPreview.vue'
import ClinicBlocks from './ClinicBlocks.vue'
import ClinicHero from './ClinicHero.vue'
import { ItemMeta } from '@/components'

export default {
  components: {
    ItemMeta,
    LocaleField,
    ClinicSharingPreview,
    ClinicBlocks,
    ClinicHero,
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      clinic: this.value
    }
  },
  watch: {
    clinic: {
      deep: true,
      handler (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.cv__share {
}
</style>
