import {
  CHANNEL_TG,
  CHANNEL_WA,
  CHANNEL_LINK,
  CHANNEL_EMAIL
} from './ContactChannel'


export default class ContactsList {
  constructor (list) {
    const items = list || []
    return [
      CHANNEL_TG,
      CHANNEL_WA,
      CHANNEL_LINK,
      CHANNEL_EMAIL,
    ].map((channel) => {
      const item = items.find((item) => channel === item.channel)
      const value = item ? item.value : null
      return {
        channel,
        value
      }
    })
  }
}
