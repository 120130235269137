<template>
  <div class="contacts-list-editor">
    <div v-for="item in items" :key="item.channel">
      <validation-provider
        slim
        :rules="rules[item.channel]"
        v-slot="{ errors }"
      >
        <v-text-field
          v-model="item.value"
          :label="$t(`contact.channel.name.${item.channel}`)"
          @input="() => $emit('input', items)"
          hide-details
          dense
          outlined
          :error-messages="errors"
        />
      </validation-provider>
    </div>
  </div>
</template>

<script>
import isEqual from 'lodash/isEqual'
import ContactsList from '@/models/ContactsList'

export default {
  props: {
    rules: {
      type: Object,
      default: () => ({})
    },
    value: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      items: new ContactsList(this.value)
    }
  },
  watch: {
    value: {
      deep: true,
      handler (value) {
        const items = new ContactsList(value)
        if (isEqual(value, items)) return
        this.items = items
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.contacts-list-editor {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}
</style>
