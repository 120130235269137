<template>
    <div>
      <v-expansion-panels class="mb-4">
        <v-expansion-panel
          v-for="way in ways"
          :key="way.id"
        >
          <v-expansion-panel-header>
            {{ way.title.ru || way.title.en || 'Введите название' }}
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <locale-field
              component="v-text-field"
              v-model="way.title"
              name="title"
              label="Название"
            />

            <br><br>

            <locale-field
              component="quill"
              :options="quillExtended"
              v-model="way.description"
              label="Описание"
              name="description"
            />

            <v-btn
              color="error"
              @click.prevent="onRemove(way.id)"
            >
              Удалить
            </v-btn>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-btn
        color="success"
        @click.prevent="onAdd"
      >
        Добавить
      </v-btn>


    </div>
</template>

<script>
import LocaleField from '@/components/Locale/LocaleField.vue'
import { QUILL_EXTENDED } from './elements/quilljs/configs'
import { v4 as uuid } from 'uuid'

export default {
  components: {
    LocaleField
  },
  data() {
    return {
      quillExtended: QUILL_EXTENDED,
      ways: this.items
    }
  },
  props: ['items'],
  methods: {
    onAdd() {
      this.ways.push({id: uuid(), title: 'Заголовок', description: ''})
    },
    onRemove(id) {
      this.ways = this.ways.filter(item => item.id !== id)
      this.$emit('remove-way', this.ways)
    }
  }
}
</script>
