export const CHANNEL_TG = 'telegram'
export const CHANNEL_WA = 'whatsapp'
export const CHANNEL_LINK = 'link'
export const CHANNEL_EMAIL = 'email'

export const channels = {
  CHANNEL_TG,
  CHANNEL_WA,
  CHANNEL_LINK,
  CHANNEL_EMAIL,
}
