<template>
  <div>
    <v-row>
      <v-col>
        <person-card
          v-if="state.employee"
          :image="state.employee.avatarUrl"
          :name="state.employee.fullName"
        >
          <template #actions>
            <v-btn @click="() => state.employee = null" icon><v-icon small>mdi-close</v-icon></v-btn>
          </template>
        </person-card>
        <validation-provider v-else name="employee" slim rules="required" v-slot="{ errors }">
          <employee-select
            v-model="state.employee"
            hide-details="auto"
            outlined
            dense
            :error-messages="errors"
          />
        </validation-provider>
      </v-col>
      <v-col>
        <validation-provider name="type" slim rules="required" v-slot="{ errors }">
          <clinic-manager-type-select
            v-model="state.type"
            hide-details="auto"
            outlined
            dense
            :error-messages="errors"
          />
        </validation-provider>
      </v-col>
    </v-row>

    <contacts-list-editor v-model="state.contacts" />

  </div>
</template>

<script>
import isEqual from 'lodash/isEqual'
import ClinicManagerTypeSelect from './ClinicManagerTypeSelect.vue'
import EmployeeSelect from '@/components/Employee/EmployeeSelect.vue'
import ContactsListEditor from '@/components/Contact/ContactsListEditor.vue'
import PersonCard from '@/components/Person/PersonCard.vue'
import GeneralManager from '@/models/GeneralManager'

export default {
  components: {
    PersonCard,
    ContactsListEditor,
    EmployeeSelect,
    ClinicManagerTypeSelect,
  },
  props: {
    value: {
      type: Object,
    }
  },
  data () {
    return {
      state: new GeneralManager(this.value)
    }
  },
  watch: {
    value: {
      deep: true,
      handler (value) {
        const state = new GeneralManager(value)
        if (isEqual(state, this.state)) return
        this.state = state
      }
    },
    state: {
      deep: true,
      handler (state) {
        this.$emit('input', state)
      }
    }
  }
}
</script>
